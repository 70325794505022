import form from '../../../utils/form';

function initialState() {
    return {
        item: {
            firstName:null,
            lastName:null,
            message: null,
            phone: null,
            email: null,
            checkbox: false,
            category_id:null,
            digit : null
        },
        form: {
            firstName:null,
            lastName:null,
            message: null,
            phone: null,
            email: null,
            checkbox: false,
            category_id:null,
            digit : null
        },
        errors: {},
        loading: false,
        selectedCategory: [],
        categories: [],
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
    categories: state => state.categories,
    selectedCategory: state => state.selectedCategory,
};

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)));
            axios.post(`get-in-touch`, params)
                .then(response => {
                    commit('resetForm');
                    resolve(response.data);
                })
                .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    getCategories({commit}) {
        axios.get(`request/getCategories`)
            .then(response => {
                if(!_.isEmpty(response.data)){
                    commit("setCategories", _.get(response, 'data', []));
                }
            });
    },
    onSearch({commit}, {search, loading}){
        commit('setLoading', true);
        _.debounce((search) => {
            axios.get(
                `request/searchCategory?q=${escape(search)}`
            ).then(res => {
                commit("setCategories", _.get(response, 'data', []));
            }).catch(err => {
                commit('setLoading', false);
            });
        }, 350)(search);
    },
    setCategories({commit}, value) {
        commit('setCategories', value);
    },
    setCategory({commit}, value) {
        commit('setCategory', value);
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value})
    },
    resetState({ commit }) {
        commit('resetState');
    },
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setCategories(state, value) {
        state.categories = value;
    },
    setSelectedCategory(state, value) {
        state.selectedCategory = value;
    },
    setCategory(state, value) {
        state.selectedCategory = value;
        state.item.category_id = value.id;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    resetForm(state) {
        _.merge(state.item, state.form);
        state.selectedCategory = [];
    },
    setItemProperty(state, {key, value}){
        _.set(state.item, key, value);
        _.set(state.item.checkbox, false);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

