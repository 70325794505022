<template>
    <div class="input-error text-danger mt-1" v-if="hasError">
        <span v-for="message in getErrorMessages">{{ message }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String
            },
            errors: {
                // type: Object | Array,
                type: [ Object, Array ],
                default: {}
            }
        },
        computed: {
            hasError(){
                return (_.has(this.errors, this.name));
            },
            getErrorMessages(){
                return _.get(this.errors, this.name, []);
            }
        }
    }
</script>

<style scoped>

</style>
