<template>
    <div v-if="this.isHidden == false">
        <p class="text-secondary">We value your investment and offer free customization with every report. </p>
        <div class="alert alert-danger alert-dismissible" v-if="this.errorMessage ">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong>{{ this.errorMessage }}</strong>
        </div>
        <form @submit.prevent="submitForm" novalidate>
            <div class="row">
                <div class="mb-3 col-md-4 col-lg-4">
                    <div class="form-group">
                        <input
                            id="firstName"
                            placeholder="First Name *"
                            type="text"
                            class="form-control"
                            name="firstName"
                            :value="item.firstName"
                            @input="updateInput"
                            required
                        >
                        <input-error :errors="errors" :name="'firstName'"></input-error>
                    </div>
                </div>
                <div class="mb-3 col-md-4 col-lg-4">
                    <div class="form-group">
                        <input
                            id="lastName"
                            placeholder="Last Name *"
                            type="text"
                            class="form-control"
                            name="lastName"
                            :value="item.lastName"
                            @input="updateInput"
                            required
                        >
                        <input-error :errors="errors" :name="'lastName'"></input-error>
                    </div>
                </div>
                <div class="mb-3 col-md-8 col-lg-8">
                    <div class="form-group">
                        <input
                            id="email"
                            placeholder="Email *"
                            type="text"
                            class="form-control"
                            name="email"
                            :value="item.email"
                            @input="updateInput"
                            required
                        >
                        <input-error :errors="errors" :name="'email'"></input-error>
                    </div>
                </div>
                <div class="mb-3 col-md-8 col-lg-8">
                    <div class="form-group">
                        <input
                            id="phone"
                            placeholder="Phone *"
                            type="text"
                            class="form-control"
                            name="phone"
                            :value="item.phone"
                            @input="updateInput"
                            required
                        >
                        <input-error :errors="errors" :name="'phone'"></input-error>
                    </div>
                </div>
                <div class="mb-3 col-md-8 col-lg-8">
                    <div class="form-group">
                        <textarea
                            id="message"
                            placeholder="Specific research requirements *"
                            type="text"
                            class="form-control"
                            name="message"
                            rows="6"
                            :value="item.message"
                            @input="updateInput"
                            required
                        ></textarea>
                        <input-error :errors="errors" :name="'message'"></input-error>
                    </div>
                </div>
                <div class="mb-3 col-md-8 col-lg-8">
                    <div class="form-check form-check-inline">
                        <input
                            id="checkbox"
                            type="checkbox"
                            class="form-check-input"
                            name="checkbox"
                            :value="'true'"
                            :checked="item.checkbox === true"
                            @change="updateCheckbox"
                            required
                        >
                        Yes, I have read the <a href="https://www.grandviewresearch.com/info/privacy-policy" class="text_Bondi_Blue text_underline ml-1" target="_blank"> privacy policy</a>
                    </div>
                    <input-error :errors="errors" :name="'checkbox'"></input-error>
                </div>
                <div class="mb-3 col-md-8 col-lg-8">
                    <div class="form-group">
                        <div class="font-s-13 text-muted">This website is secure and your personal details are safe. </div>
                    </div>
                </div>
                <div class="mb-3 col-lg-12 col-12">
                    <div class="form-group">
                        <vue-button-spinner
                            class="btn gv_primary_btn"
                            :isLoading="loading"
                            :disabled="loading"
                        >
                            Request for special pricing
                        </vue-button-spinner>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="text-center" v-if="this.isHidden == true">
        <thank-you></thank-you>
    </div>
</template>

<script>
    import { get} from 'lodash';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        data() {
            return {
                xprops: {
                    module: 'SpecialPriceSingle'
                },
                isHidden : false,
                errorMessage : null,
            }
        },
        props:{
            id : null,
            type : null,
        },
        mounted() {
        },
        created() {
        },
        unmounted() {
            this.resetState();
        },
        computed: {
            ...mapGetters('SpecialPriceSingle', ['item', 'loading', 'errors']),
        },
        methods: {
            ...mapActions('SpecialPriceSingle', ['storeData', 'setItemProperty', 'resetErrors', 'resetState']),
            submitForm() {
                let id = this.id;
                let type = this.type;
                this.storeData({id,type})
                    .then((response) => {
                        /*this.$toast.success('Request sent successfully.', {
                            position: "top-right",
                            duration: 1500
                        });*/
                        _.set(this,'isHidden',true);
                        // this.$router.push({name: 'special.price'});
                    })
                    .catch((error) => {
                        _.set(this,"errorMessage", 'OOPS! Error Occured.');
                        /*this.$toast.info('OOPS! Error Occured.', {
                            position: "top-right",
                            duration: 1500
                        });*/
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key, value});
            },
        },
    }
</script>

<style scoped>

</style>
