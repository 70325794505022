<template>
    <div class="col-lg-12 col-md-8 col-sm-10 col-12" v-if="this.isHidden == false">
        <div class="alert alert-danger alert-dismissible" v-if="this.errorMessage ">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong>{{ this.errorMessage }}</strong>
        </div>
        <form @submit.prevent="submitForm" novalidate>
            <div class="row">
                <div class="mb-3 col-md-6">
                    <input
                        id="firstName"
                        placeholder="First Name *"
                        type="text"
                        class="form-control"
                        name="firstName"
                        :value="item.firstName"
                        @input="updateInput"
                        required
                    >
                    <input-error :errors="errors" :name="'firstName'"></input-error>
                </div>
                <div class="mb-3 col-md-6">
                    <input
                        id="lastName"
                        placeholder="Last Name *"
                        type="text"
                        class="form-control"
                        name="lastName"
                        :value="item.lastName"
                        @input="updateInput"
                        required
                    >
                    <input-error :errors="errors" :name="'lastName'"></input-error>
                </div>
            </div>
            <div class="mb-3">
                <input
                    id="email"
                    placeholder="Email *"
                    type="email"
                    class="form-control"
                    name="email"
                    :value="item.email"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'email'"></input-error>
            </div>
            <div class="mb-3">
                <input
                    id="phone"
                    placeholder="Phone* (Pls. Affix Country Code)"
                    type="text"
                    class="form-control"
                    name="phone"
                    :value="item.phone"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'phone'"></input-error>
            </div>
            <div class="mb-3">
                <div class="input select">
                    <VueMultiselect
                        v-model="selectedCategory"
                        :searchable="true"
                        id="category_id"
                        :options="categories"
                        :placeholder="'Select Industry'"
                        track-by="id"
                        label="name"
                        @search-change="searchCategory"
                        @select="updateCategory($event)"
                    >
                    </VueMultiselect>
                    <input-error :errors="errors" :name="'category_id'"></input-error>
                </div>
            </div>
            <div class="mb-3">
                <textarea
                    id="message"
                    placeholder="Specific research requirements *"
                    type="text"
                    class="form-control"
                    name="message"
                    rows="6"
                    :value="item.message"
                    @input="updateInput"
                    required
                ></textarea>
                <input-error :errors="errors" :name="'message'"></input-error>
            </div>
            <div class="mb-3">
                <div class="row justify-content-md-center">
                    <div class="col-sm-6 col-12 mb-3 mb-sm-0">
                        <input
                            id="digit"
                            type="text"
                            class="form-control ui-flat"
                            placeholder="Enter Captcha"
                            name="digit"
                            :value="item.digit"
                            @input="updateInput"
                            maxlength="5"
                            required
                        >
                        <input-error :errors="errors" :name="'digit'"></input-error>
                    </div>
                    <div class="col-md-4 col-4 text-center text-sm-left">
                        <img id="captcha-mgm" src='/loadCaptcha'>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-check d-table mx-auto mb-3">
                    <input
                        id="checkbox"
                        type="checkbox"
                        class="form-check-input"
                        name="checkbox"
                        :value="'true'"
                        :checked="item.checkbox === true"
                        @change="updateCheckbox"
                        required
                    >
                    <label class="form-check-label" for="checkbox">
                        Yes, I have read the <a href="https://www.grandviewresearch.com/info/privacy-policy" class="text_Bondi_Blue text_underline ml-1" target="_blank"> privacy policy</a>
                    </label>
                </div>
                <div class="row justify-content-md-center">
                    <input-error :errors="errors" :name="'checkbox'"></input-error>
                </div>
            </div>
            <div class="mt-3 form-group">
                <div class="">
                    <vue-button-spinner
                        class="btn btn-submit"
                        :isLoading="loading"
                        :disabled="loading"
                    >
                        Submit to download Astra's ESG Services Brochure for Free
                    </vue-button-spinner>
                </div>
            </div>
        </form>
    </div>
    <div class="col-12 text-center" v-if="this.isHidden == true">
        <thank-you></thank-you>
        <company-brochure-download></company-brochure-download>
    </div>
</template>

<script>
    import { get} from 'lodash';
    import { mapGetters, mapActions } from 'vuex';
    import VueMultiselect from "vue-multiselect";

    export default {
        components: {
            VueMultiselect
        },

        data() {
            return {
                xprops: {
                    module: 'GetInTouchSingle'
                },
                isHidden : false,
                errorMessage : null,
            }
        },
        mounted() {
        },
        created() {
            this.getCategories();
        },
        unmounted() {
            this.resetState();
        },
        computed: {
            ...mapGetters('GetInTouchSingle', ['item', 'loading',  'errors', 'categories', 'selectedCategory']),
        },
        methods: {
            ...mapActions('GetInTouchSingle', ['storeData', 'setItemProperty', 'resetErrors', 'resetState',
                'getCategories', 'onSearch', 'setCategory']),
            submitForm() {
                this.storeData()
                    .then((response) => {
                        //_.set(this,'isHidden',true);
                        window.location.href = "/thank-you";
                    })
                    .catch((error) => {
                        _.set(this,"errorMessage", 'OOPS! Error Occured.');
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key, value});
            },
            updateSelect(e){
                this.setStatus(e);
            },
            updateCategory(e){
                this.setCategory(e);
            },
            searchCategory(search, loading){
                if(!_.isEmpty(search)) {
                    this.onSearch({search:search, loading:loading});
                }else{
                    this.onSearch({search:'', loading:loading});
                }
            },
        },
    }

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>
