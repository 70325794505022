import form from '../../../utils/form';

function initialState() {
    return {
        item: {
            firstName:null,
            lastName:null,
            message: null,
            phone: null,
            email: null,
            digit: null,
            checkbox: false,
        },
        form: {
            firstName:null,
            lastName:null,
            message: null,
            phone: null,
            email: null,
            digit: null,
            checkbox: false,
        },
        errors: {},
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    errors: state => state.errors,
    loading: state => state.loading,
};

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            let params = form.formData(_.pick(state.item, _.keys(state.form)));
            axios.post(`analyst/call`, params)
                .then(response => {
                    commit('resetForm');
                    resolve(response.data);
                })
                .catch(error => {
                    let message = error.message;
                    commit("setErrors", _.get(error.response.data, 'errors', {}));
                    reject(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        });
    },
    resetErrors({ commit }) {
        commit('setErrors', {});
    },
    setItemProperty({ commit }, {key, value}) {
        commit('setItemProperty', {key, value})
    },
    resetState({ commit }) {
        commit('resetState');
    },
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setErrors(state, value) {
        state.errors = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    resetForm(state) {
        _.merge(state.item, state.form);
    },
    setItemProperty(state, {key, value}){
        _.set(state.item, key, value);
        _.set(state.item.checkbox, false);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};

