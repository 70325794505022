require("./bootstrap");
require("./theme");

import {createApp} from "vue";
import App from "./components/App";
import store from "./store/index";
import router from "./routes/index";
import Toaster from '@meforma/vue-toaster';

const app = createApp(App)
    .use(store)
    .use(Toaster)
    .use(router);

    app.component('input-error', require('./components/Common/InputError.vue').default);
    app.component('vue-button-spinner', require('./components/Common/VueButtonSpinner.vue').default);
    app.component('back-button', require('./components/Common/BackButton.vue').default);
    app.component('brochure', require('./components/Report/Brochure.vue').default);
    app.component('inquire', require('./components/Report/Inquiry.vue').default);
    app.component('report-inquire', require('./components/Report/ReportInquiry.vue').default);
    app.component('custom-research', require('./components/Report/CustomResearch.vue').default);
    app.component('free-consultation', require('./components/Report/FreeConsultation.vue').default);
    app.component('special-price', require('./components/Report/SpecialPrice').default);
    app.component('analyst-call', require('./components/Services/ScheduleAnalyst').default);
    app.component('request-sample', require('./components/Report/RequestSample').default);
    app.component('sample-copy', require('./components/Report/SampleCopy').default);
    app.component('thank-you', require('./components/Common/Thanks').default);
    app.component('company-brochure-download', require('./components/Common/CompanyBrochureDownload').default);

    app.component('get-in-touch', require('./components/Services/GetInTouch').default);
    app.component('service-request', require('./components/Services/ServiceRequest').default);
    app.mount("#app-layout");
