<script>
export default {
    data() {
        return {
            _: window._,
            moment: window.moment
        }
    },
}
</script>

<style scoped>

</style>
