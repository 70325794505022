let date_format     = document.head.querySelector('meta[name="dp-date"]');
let time_format     = document.head.querySelector('meta[name="dp-time"]');
let datetime_format = document.head.querySelector('meta[name="dp-datetime"]');
let app_locale      = document.head.querySelector('meta[name="app-locale"]');

if (date_format && time_format && datetime_format) {
    window.date_format_moment     = date_format.content;
    window.time_format_moment     = time_format.content;
    window.datetime_format_moment = datetime_format.content;
    window.app_locale             = app_locale.content;
} else {
    console.error('Moment.js date and time formats not found');
}

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
window._ = require('lodash');
window.moment = require('moment-timezone');
window.purify = o => JSON.parse(JSON.stringify(o));
import axios from "./utils/http";
window.axios = axios;
require('bootstrap');

moment.updateLocale(window.app_locale, {
    week: {
        dow: 1
    }
});

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.moment = require('moment-timezone');
    require('bootstrap');
} catch (e) {}
