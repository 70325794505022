import {createRouter, createWebHistory} from 'vue-router';

import NotFound from '../components/NotFound';
import Dashboard from '../components/Dashboard';

const routes = [
    { path: '/', component: Dashboard, name: 'home' },

    { path: '/:pathMatch(.*)*', component: NotFound, name: 'not.found' },
];

export default createRouter({
    history: createWebHistory('/'),
    mode: 'history',
    routes
});
