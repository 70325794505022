<template>
    <div v-if="this.isHidden == false">
        <div class="alert alert-danger alert-dismissible" v-if="this.errorMessage ">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong>{{ this.errorMessage }}</strong>
        </div>
        <form @submit.prevent="submitForm" novalidate>
            <div class="mb-3 form-group">
                <input
                    id="firstName"
                    placeholder="First Name *"
                    type="text"
                    class="form-control ui-flat"
                    name="firstName"
                    :value="item.firstName"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'firstName'"></input-error>
            </div>
            <div class="mb-3 form-group">
                <input
                    id="lastName"
                    placeholder="Last Name *"
                    type="text"
                    class="form-control ui-flat"
                    name="lastName"
                    :value="item.lastName"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'lastName'"></input-error>
            </div>
            <div class="mb-3 form-group">
                <input
                    id="email"
                    placeholder="Email *"
                    type="text"
                    class="form-control ui-flat"
                    name="email"
                    :value="item.email"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'email'"></input-error>
            </div>
            <div class="mb-3 form-group">
                <input
                    id="phone"
                    placeholder="Phone*"
                    type="text"
                    class="form-control ui-flat"
                    name="phone"
                    :value="item.phone"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'phone'"></input-error>
            </div>
            <div class="mb-3 form-group">
                <textarea
                    id="message"
                    placeholder="Specific research requirements *"
                    type="text"
                    class="form-control ui-flat"
                    name="message"
                    rows="6"
                    :value="item.message"
                    @input="updateInput"
                    required
                ></textarea>
                <input-error :errors="errors" :name="'message'"></input-error>
            </div>
            <div class="mb-3 form-group row">
                <div class="col-md-12 label">
                    Enter captcha code
                </div>
                <div class="col-md-6 col-6 pr-2">
                    <input
                        id="digit"
                        type="text"
                        class="form-control ui-flat"
                        name="digit"
                        :value="item.digit"
                        @input="updateInput"
                        maxlength="5"
                        required
                    >
                </div>
                <div class="mb-3 col-md-6 col-6 pl-2">
                    <img id="captcha-mgm" src="/loadCaptcha">
                </div>
                <input-error class="col-md-12 col-12" :errors="errors" :name="'digit'"></input-error>
            </div>
            <div class="form-group">
                <div class="form-check form-check-inline mb-0 font-s-13">
                    <input
                        id="checkbox"
                        type="checkbox"
                        class="form-check-input"
                        name="checkbox"
                        :value="'true'"
                        :checked="item.checkbox === true"
                        @change="updateCheckbox"
                        required
                    >
                    Yes, I have read the <a href="https://www.grandviewresearch.com/info/privacy-policy" class="text_Bondi_Blue text_underline ml-1" target="_blank"> privacy policy</a>
                </div>
                <input-error :errors="errors" :name="'checkbox'"></input-error>
            </div>
            <div class="form-group">
                <div class="font-s-13"> We never share your personal data. </div>
            </div>
            <div class="form-group">
                <vue-button-spinner
                    class="btn gv_info_btn ml-5 shadow-none "
                    :isLoading="loading"
                    :disabled="loading"
                >
                    Submit Query
                </vue-button-spinner>
            </div>
        </form>
    </div>
    <div class="text-center" v-if="this.isHidden == true">
        <thank-you></thank-you>
    </div>
</template>

<script>
    import { get} from 'lodash';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        data() {
            return {
                xprops: {
                    module: 'AnalystSingle'
                },
                isHidden : false,
                errorMessage : null,
            }
        },
        mounted() {
        },
        created() {
        },
        unmounted() {
            this.resetState();
        },
        computed: {
            ...mapGetters('AnalystSingle', ['item', 'loading',  'errors']),
        },
        methods: {
            ...mapActions('AnalystSingle', ['storeData', 'setItemProperty', 'resetErrors', 'resetState']),
            submitForm() {
                this.storeData()
                    .then((response) => {
                        /*this.$toast.success('Request sent successfully.',{
                            position: "top-right",
                            duration: 1500
                        });*/
                        //_.set(this,'isHidden',true);
                        // window.location = '/request/thanks';
                        window.location.href = "/thank-you";
                    })
                    .catch((error) => {
                        _.set(this,"errorMessage", 'OOPS! Error Occured.');
                        /*this.$toast.info('OOPS! Error Occured.',{
                            position: "top-right",
                            duration: 1500
                        });*/
                    });
            },
            updateInput(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.value;
                this.setItemProperty({key, value});
            },
            updateCheckbox(e) {
                let key = e.target.getAttribute("name");
                let value = e.target.checked;
                this.setItemProperty({key, value});
            },
        },
    }
</script>

<style scoped>

</style>
