require("owl.carousel");

/* Working New Layout */
$("#gv_client_slide").owlCarousel(
    {
        dots: false,
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: true
    }
);
$(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
        $('.gv_scrollup').fadeIn();
    } else {
        $('.gv_scrollup').fadeOut();
    }
});
$('.gv_scrollup').click(function () {
    $("html, body").animate({scrollTop: 0}, 600);
    return false;
});
/* // Working New Layout */

function scrollToDiv() {
    $('html, body').animate({scrollTop: $('#fill-form').offset().top}, 'slow');
    return false;
}
function scrollToForm() {
    var ele = document.getElementById('fill-form');
    window.scrollTo(ele.offsetLeft, ele.offsetTop - 65);
}
function forprint() {
    if (!window.print) {
        return
    }
    window.print()
}
function onScroll(event) {
    var scrollPos = $(document).scrollTop() + 200;
    $('#scroll-spy-fixed a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            $('#scroll-spy-fixed ul li a').removeClass("active");
            currLink.addClass("active");
        } else {
            currLink.removeClass("active");
        }
    });
}

$(document).ready(function () {
    if($("div#room").find('a').attr('href')){
        var report_slug = $("div#room").find('a').attr('href').substring($('div#room').find('a').attr('href').lastIndexOf('/') + 1);
        $("#rfc1").removeAttr('href');
        $("#rfc2").removeAttr('href');
        $("#rfc3").removeAttr('href');
        $("#proceed2Buy").removeAttr('href');
        $('#rfc1').attr('href', '/'+report_slug+'/request/rs5');
        $('#rfc2').attr('href', '/'+report_slug+'/request/rs6');
        $('#rfc3').attr('href', '/pipeline/reports/'+report_slug+'/request/rs6');
        $('#proceed2Buy').attr('href', '/checkout/select-license/'+report_slug);
    }

    $('div#services').find('p').each(function () {
        $(this).find('img').each(function () {
            if($(this).attr('src') != undefined){
                $(this).css('height', '340px');
                $(this).css('width', '580px');
            }
        })
    });

    /*$('.form-control').on("focus", function(index){
        if($(this).find('div.input-error')){
            $('div.form-group').find('div.input-error').remove();
        };
    });*/


    $('.gv_report_summary.full').find("p").each(function () {
        if ($(this).css('text-align') == 'justify') {
            $(this).css('text-align', 'center');
            $(this).css('padding-left', '0');
            //$(this).addClass('paddLeft');
        }
    });


    $('div#room').find("p").each(function () {
        $(this).find('a').each(function(index, value){
            var mystring = 'http://www.grandviewresearch.com/industry-analysis';
            var text = $(this).text();
            text = text.replace(mystring, window.location.origin);
            $(this).text(text);
            $(this).css("font-weight","Bold");
            $(this).attr('href', text);
        })
    });

    /*$('div#rprt_summary').find("img").each(function (index, value) {
        var fcaou = (index + 2);
        var dsrc = decodeURI($(this).attr('src'));
        $(this).attr('width', '670');
        $(this).attr('height', '350');
        $(this).addClass('lazyload');
        $(this).attr('data-src', dsrc);
        $(this).after('<p style="margin-bottom:30px;"> To learn more about this report, <a href="'+window.location.pathname+'/request/rs' + fcaou + '" style="color:blue;"> request a free demo </a> </p>');

    });*/

    /*var htm_jon = '<h2 class="custom_h2"><strong>Report Coverage & Deliverables</strong></h2>' +
        '<div class="top_summary_box row" style="min-height:332px;">' +
        '<div class="col-lg-5 col-md-6 top_summary_left_box">' +
        '<div class="title_top_summary_box">PDF report & online dashboard will help you understand:</div>' +
        '<ul>' +
        '<li> <i class="fa fa-check"></i> Competitive benchmarking</li>' +
        '<li> <i class="fa fa-check"></i> Historical data & forecasts</li>' +
        '<li> <i class="fa fa-check"></i> Company revenue shares</li>' +
        '<li> <i class="fa fa-check"></i> Regional opportunities</li>' +
        '<li> <i class="fa fa-check"></i> Latest trends & dynamics</li>' +
        '</ul>' +
        '<a class="reqest_copy_btn" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#market_platform_form2">Request a Free demo</a>' +
        '</div>' +
        '<div class="col-lg-7 col-md-6 top_summary_right_box">' +
        '<div class="top_summary_box_img_hover">' +
        '<img data-src="/static/img/report-showing-img.jpg" src="/static/img/report-showing-img.jpg" width="380" height="291" class="top_summary_box_img lazyload" alt="The Grand Library - BI Enabled Market Research Database" />' +
        '<a data-bs-toggle="modal" data-bs-target="#myModal">Click on image to enlarge</a>' +
        '</div>' +
        '</div>' +
        '</div>';*/
    /*var hasCovidTable = $('div#rprt_summary').find("table").length;
    if (hasCovidTable > 1) {
        var strng = $('div#rprt_summary h2:eq(3)');
    } else {
        var strng = $('div#rprt_summary h2:eq(2)');
    }*/
    /*var strng = $('div#rprt_summary p:eq(12)');
    var strng1 = $('div#rprt_summary h2:eq(2)');
    $(htm_jon).insertBefore(strng);
    $(htm_jon).insertBefore(strng1);

    $('div#rprt_summary').find("table").each(function (index, value) {
        var h2Tag = $(this).prev();
        var table = $(this).html();
        var h2Html = $(h2Tag).html();
        var hasCovidTable = $('div#rprt_summary').find("table").length;
        if (index == 0 && hasCovidTable > 1) {
            var newHtml = '<div class="highlight" style="background: #f5fafc;padding: 25px 0px;box-shadow: 0 .3rem 1rem rgba(0,0,0,.10);border: 1px solid #ddd;border-radius: 2px;"><h2 style="text-align:center;"><strong>' + h2Html + '</strong></h2><table style="width: 700px; margin-left: auto; margin-right: auto;" width="0" cellspacing="00" border="1">' + table + '</table><p style="text-align: center;margin-top: 15px;margin-bottom: 0px;"><a href="'+window.location.pathname+'/request/covid2" style="text-decoration: none !important;color: blue;">How has COVID19 impacted this market? To know more request a free demo</a></p></div>';
        } else {
            var newHtml = '<div class="highlight" style="background: #f5fafc;padding: 25px 0px;box-shadow: 0 .3rem 1rem rgba(0,0,0,.10);border: 1px solid #ddd;border-radius: 2px;"><h2 style="text-align:center;"><strong>' + h2Html + '</strong></h2><table style="width: 700px; margin-left: auto; margin-right: auto;" width="0" cellspacing="00" border="1">' + table + '</table></div>';
        }
        $(newHtml).insertBefore(this);
        $(this).remove();
        $(h2Tag).remove();
    });*/

    if (window.matchMedia("(max-width: 767px)").matches) {
    } else {
        // $("#market_platform_form_covid19").modal('show');
    }

    var modal = document.getElementById('myModal');
    $(document).on('click', 'div#rprt_summary img.top_summary_box_img', function () {
        $(modal).modal('show');
    });

});

/* Report Filter */
$(document).ready(function() {
    $('#reportFilter').on('submit',function(e){
        e.preventDefault();
        let currentNode = window.location.pathname;
        let name = $('#search').val();
        let sector = $('#sector').val();
        let sort_by = $('#sort_by').val();

        if(name && sort_by && sector){
            name = name.trimEnd().trimStart().replaceAll(' ','+');
            let url = currentNode+"?search="+name+"&sortBy="+sort_by+"&sector="+sector;
            window.location.href=url;
        }
        else if(name && sort_by && !sector){
            name = name.trimEnd().trimStart().replaceAll(' ','+');
            let url = currentNode+"?search="+name+"&sortBy="+sort_by;
            window.location.href=url;
        }
        else if(name && sector && !sort_by){
            name = name.trimEnd().trimStart().replaceAll(' ','+');
            let url = currentNode+"?search="+name+"&sector="+sector;
            window.location.href=url;
        }
        else if(!name && sort_by && sector){
            if(window.location.pathname.split('/')[1] == 'sector'){
                let url = '/sector/'+sector+"?sortBy="+sort_by+"&sector="+sector;
                window.location.href=url;
            }
            else{
                let url = currentNode+"?sortBy="+sort_by+"&sector="+sector;
                window.location.href=url;
            }
        }
        else if(!name && !sort_by && sector){
            if(window.location.pathname.split('/')[1] == 'sector'){
                let url = '/sector/'+sector+"?sector="+sector;
                window.location.href=url;
            }
            else{
                let url = currentNode+"?sector="+sector;
                window.location.href=url;
            }
        }
        else if(!name && sort_by && !sector){
            let url = currentNode+"?sortBy="+sort_by;
            window.location.href=url;
        }
        else{
            window.location.href=currentNode;
        }

    });
});

/* // Report filter */

/* Report detail side bar */
$(document).ready(function () {

/*     setTimeout(() => {
    var $window = $(window);
    var $sidebar = $(".free_smaple_right_bar");
    var $sidebarHeight = $sidebar.innerHeight();
    var $footerOffsetTop = $("footer").offsetTop - 50;
    var $sidebarOffset = $sidebar.offsetHeight;

    $window.scroll(function () {
        if ($window.scrollTop() > ($sidebarOffset.top + 370)) {
            $sidebar.addClass("sticky_bar");
        } else {
            $sidebar.removeClass("sticky_bar");
        }

        var fHight = ($window.scrollTop() + $sidebarHeight);
        if (fHight > $footerOffsetTop) {
            $sidebar.css({"top": -($window.scrollTop() + $sidebarHeight - $footerOffsetTop)});
        } else {
            $sidebar.css({"top": "50px", });
        }
    });
}, "2000");
*/
});
/* // Report detail side bar */

$(document).ready(function () {
    //$('body').cookiefy({displayedHtml: 'This site uses cookies to improve user experience. <a href="/info/privacy-policy" target="_blank"> More Info</a>', backgroundColor: '#EEEEEE', color: '#404040', fontFamily: 'Trebuchet MS', fontSize: '13px', borderTop: '0px solid #fff', devMode: false});
    $('[data-bs-toggle="tooltipmini"]').tooltip({html: true});
    $('[data-bs-toggle="tooltip"]').tooltip({html: true, template: '<div class="tooltip bi_tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'});
    $('[data-bs-toggle="popover"]').popover({html: true});
    $('body').on('click', function (e) {
        if ($(e.target).data('toggle') !== 'popover' && $(e.target).parents('.popover.in').length === 0) {
            $('[data-bs-toggle="popover"]').popover('hide');
        }
    });

    if ($(window).scrollTop() > 100) {
        $('.scrollToTop').fadeIn();
    } else {
        $('.scrollToTop').fadeOut();
    }
    $('div.dropdown').hover(function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
    }, function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
    });
    $(document).on("scroll", onScroll);
    $('.smooth-active').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");
        $('a').each(function () {
            $(this).removeClass('active');
        });
        $(this).addClass('active');
        var target = this.hash, menu = target;
        $target = $(target);
        $('html, body').stop().animate({'scrollTop': $target.offset().top - 65}, 500, 'swing', function () {
            $(document).on("scroll", onScroll);
        });
    });

    var owl = $("#owl-demo");
    owl.owlCarousel({itemsCustom: [[0, 1], [435, 2], [768, 3], [992, 4], [1200, 5]], slideSpeed: 1000, paginationSpeed: 1200, rewindSpeed: 100, autoPlay: 3000, navigation: false, navigationText: ["&lsaquo;", "&rsaquo;"]});
    $("#owl-demo-bi").owlCarousel({navigation: true, slideSpeed: 1000, autoPlay: 6000, pagination: false, rewindSpeed: 1200, autoHeight: true, singleItem: true, stopOnHover: true, nav: false, loop: true, navigationText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]});
});
$(window).scroll(function () {
    if ($(window).scrollTop() > 100) {
        $('.scrollToTop').fadeIn();
    } else {
        $('.scrollToTop').fadeOut();
    }
});
$(document).on("click", "div.pagination[data-ajax] span a", function (e) {
    e.preventDefault();
    var Ajaxify = $(this).parents("div.pagination").data("ajax");
    var disabled = $(this).parent("span").hasClass("disabled");
    var url = $(this).attr("href");
    var target = $(this).parents("div.pagination").data("target");
    if (Ajaxify && !disabled && url !== "" && url !== "#" && target !== "undefined") {
        loadData(url, target, {}, "GET");
    }
});
$(document).on("submit", 'form[data-ajax="true"]', function (e) {
    var target = $(this).data("target");
    var dataType = $(this).data("datatype");
    var trigger = $(this).data("trigger");
    var closeModal = $(this).data("closemodal");
    target = (typeof target == "undefined") ? false : $(target);
    dataType = (typeof dataType == "undefined") ? "html" : dataType;
    var sForm = $(this);
    $(this).ajaxSubmit({dataType: dataType, beforeSubmit: function (arr, $form, options) {
            options.context = $form;
            addLoaderbackDrop(target);
            sForm.find('input[type="submit"], button[type="submit"]').prop('disabled', true).addClass("disabled");
            sForm.find('button[type="submit"] i').addClass("fa-circle-o-notch fa-spin");
        }, success: function (response) {
            if (typeof closeModal != "undefined") {
                $(document).find(closeModal).modal("hide");
            }
            if (typeof response == "object") {
                if (response.hasOwnProperty("result")) {
                    var result = response.result;
                    if (result.hasOwnProperty("reload")) {
                        window.location.reload(true);
                    } else if (result.hasOwnProperty("redirect")) {
                        window.location.href = result.redirect;
                    } else if (result.hasOwnProperty("html")) {
                        if (target !== false && typeof target !== "undefined") {
                            target.html(result.html);
                        }
                    }
                } else {
                    if (response.hasOwnProperty("reload")) {
                        window.location.reload(true);
                    } else if (response.hasOwnProperty("redirect")) {
                        window.location.href = response.redirect;
                    } else if (response.hasOwnProperty("html")) {
                        if (target !== false && typeof target !== "undefined") {
                            target.html(response.html);
                        }
                    }
                }
            } else {
                if (target !== false && typeof target !== "undefined") {
                    target.html(response);
                }
            }
            if (typeof trigger != "undefined" && typeof trigger == "array") {
                for (var i = 0; i < trigger.length; i++) {
                    triggerEvent = trigger[i];
                    if (triggerEvent.hasOwnProperty("el") && triggerEvent.hasOwnProperty("event")) {
                        $(triggerEvent.el).trigger(triggerEvent.event);
                    }
                }
            } else if (typeof trigger != "undefined" && typeof trigger == "object") {
                if (trigger.hasOwnProperty("el") && trigger.hasOwnProperty("event")) {
                    $(trigger.el).trigger(trigger.event);
                }
            }
            sForm.find('input[type="submit"], button[type="submit"]').prop('disabled', false).removeClass("disabled");
            sForm.find('button[type="submit"] i').removeClass("fa-circle-o-notch fa-spin");
        }, error: function (xhr) {
            sForm.find('input[type="submit"], button[type="submit"]').prop('disabled', false).removeClass("disabled");
            sForm.find('button[type="submit"] i').removeClass("fa-circle-o-notch fa-spin");
        }, complete: function (xhr) {
            removeLoaderbackDrop(target);
        }});
    e.preventDefault();
});
function loadData(url, target, data, method, message) {
    if (typeof url !== "undefined" && typeof target !== "undefined") {
        message = (typeof message === "undefined") ? "Loading...." : message;
        data = (typeof data !== "undefined" && typeof data === "object") ? data : {};
        method = (typeof method !== "undefined") ? method : "GET";
        var xhr = $.ajax({url: url, type: method, data: data, context: this, beforeSend: function (xhr) {}, success: function (response) {
                $(target).html(response);
            }, error: function (xhr) {}, complete: function () {}});
        return xhr;
    }
    return null;
}
function addLoaderbackDrop(target)
{
    var _target;
    $('.progress_bar').fadeIn("fast");
    var ajaxLoader = $(document).find("div#loaderTemplateContainer").html();
    if (typeof ajaxLoader !== "undefined") {
        if (typeof target === "string")
        {
            _target = $(document).find(target);
        }
        if (typeof target === "object")
        {
            _target = $(target);
        }
        if (_target !== "undefined") {
            _target.append(ajaxLoader);
        }
    }
}
function removeLoaderbackDrop(target)
{
    var _target;
    $('.progress_bar').fadeOut("fast");
    if (typeof target === "string")
    {
        _target = $(document).find(target + " div.loader-back-dropper");
    }
    if (typeof target === "object")
    {
        _target = $(target).find(+" div.loader-back-dropper");
    }
    if (_target !== "undefined") {
        _target.remove();
    }
}
