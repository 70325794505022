import { createStore, createLogger } from 'vuex';
import Cart from './modules/cart';
import Products from './modules/product';

import RequestSampleSingle from './modules/Report/RequestSample';
import CustomResearchSingle from './modules/Report/CustomResearch';
import AnalystSingle from './modules/Services/ScheduleAnalyst';
import SpecialPriceSingle from './modules/Report/SpecialPrice';
import BrochureSingle from './modules/Report/Brochure';
import InquirySingle from './modules/Report/Inquiry';
import FreeConsultationSingle from './modules/Report/FreeConsultation';
import SampleCopySingle from './modules/Report/SampleCopy';


import GetInTouchSingle from './modules/Services/GetInTouch';
import ServiceRequest from './modules/Services/ServiceRequest';

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        RequestSampleSingle,
        CustomResearchSingle,
        AnalystSingle,
        SpecialPriceSingle,
        BrochureSingle,
        InquirySingle,
        FreeConsultationSingle,
        SampleCopySingle,
        GetInTouchSingle,
        ServiceRequest,
        Cart,
        Products
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
