<template>
    <div class="col-lg-12 col-md-8 col-sm-10 col-12 mt-5" v-if="this.isHidden == false" >
        <h6 class="text_demi text_purple mb-3">To schedule a free market intelligence database demo, please complete the form below:</h6>
        <div class="alert alert-danger alert-dismissible" v-if="this.errorMessage ">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong>{{ this.errorMessage }}</strong>
        </div>
        <form @submit.prevent="submitForm" novalidate>
                <div class="mb-3">
                    <input
                        id="firstName"
                        placeholder="First Name *"
                        type="text"
                        class="form-control"
                        name="firstName"
                        :value="item.firstName"
                        @input="updateInput"
                        required
                    >
                    <input-error :errors="errors" :name="'firstName'"></input-error>
                </div>

                <div class="mb-3">
                    <input
                        id="lastName"
                        placeholder="Last Name *"
                        type="text"
                        class="form-control"
                        name="lastName"
                        :value="item.lastName"
                        @input="updateInput"
                        required
                    >
                    <input-error :errors="errors" :name="'lastName'"></input-error>
                </div>
            <div class="mb-3">
                <input
                    id="email"
                    placeholder="Email *"
                    type="email"
                    class="form-control"
                    name="email"
                    :value="item.email"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'email'"></input-error>
            </div>
            <div class="mb-3">
                <input
                    id="phone"
                    placeholder="Phone* (Pls. Affix Country Code)"
                    type="text"
                    class="form-control"
                    name="phone"
                    :value="item.phone"
                    @input="updateInput"
                    required
                >
                <input-error :errors="errors" :name="'phone'"></input-error>
            </div>
            <div class="mb-3">
                <textarea
                    id="message"
                    placeholder="Specific research requirements *"
                    type="text"
                    class="form-control"
                    name="message"
                    rows="6"
                    :value="item.message"
                    @input="updateInput"
                    required
                ></textarea>
                <input-error :errors="errors" :name="'message'"></input-error>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <label style="font-size: 13px;color: #555;">Enter captcha code</label>
                </div>
                <div class="col-sm-12 col-md-6 col-6 mb-3 mb-sm-0">
                    <input
                        id="digit"
                        type="text"
                        class="form-control ui-flat"
                        placeholder=""
                        name="digit"
                        :value="item.digit"
                        @input="updateInput"
                        maxlength="5"
                        required
                    >
                    <input-error :errors="errors" :name="'digit'"></input-error>
                </div>
                <div class="col-sm-12 col-md-6 col-6 text-center text-sm-left">
                    <img id="captcha-mgm"  width="97" height="31" src='/loadCaptcha'>
                </div>
            </div>

                <div class="mb-3 form-check">
                    <input
                        id="checkbox"
                        type="checkbox"
                        class="form-check-input"
                        name="checkbox"
                        :value="'true'"
                        :checked="item.checkbox === true"
                        @change="updateCheckbox"
                        required
                    >
                    <label class="form-check-label font-s-13" for="checkbox">
                        Yes, I have read the <a href="https://www.grandviewresearch.com/info/privacy-policy"
                                                class="text_Bondi_Blue text_underline ml-1" target="_blank"> privacy
                        policy</a>
                    </label>
                    <input-error :errors="errors" :name="'checkbox'"></input-error>
                </div>
            <div class="mb-3">
                <div class="">
                    <vue-button-spinner
                        class="btn bg_purple text-white"
                        :isLoading="loading"
                        :disabled="loading"
                    >
                        Submit
                    </vue-button-spinner>
                </div>
            </div>
        </form>
    </div>
    <div class="col-12 text-center" v-if="this.isHidden == true">
        <thank-you></thank-you>
    </div>
</template>

<script>
import {get} from 'lodash';
import {mapGetters, mapActions} from 'vuex';
import VueMultiselect from "vue-multiselect";

export default {
    components: {
        VueMultiselect
    },

    data() {
        return {
            xprops: {
                module: 'ServiceRequest'
            },
            isHidden: false,
            errorMessage: null,
        }
    },
    mounted() {
    },
    created() {
    },
    unmounted() {
        this.resetState();
    },
    computed: {
        ...mapGetters('ServiceRequest', ['item', 'loading', 'errors']),
    },
    methods: {
        ...mapActions('ServiceRequest', ['storeData', 'setItemProperty', 'resetErrors', 'resetState']),
        submitForm() {
            this.storeData()
                .then((response) => {
                    //_.set(this, 'isHidden', true);
                    window.location.href = "/thank-you";
                })
                .catch((error) => {
                    _.set(this, "errorMessage", 'OOPS! Error Occured.');
                });
        },
        updateInput(e) {
            let key = e.target.getAttribute("name");
            let value = e.target.value;
            this.setItemProperty({key, value});
        },
        updateCheckbox(e) {
            let key = e.target.getAttribute("name");
            let value = e.target.checked;
            this.setItemProperty({key, value});
        }
    },
}

</script>

<style scoped>

</style>
